import React from "react"
import ReactFullpage from '@fullpage/react-fullpage';

import * as Footer from 'sawada.components/Footer';

import * as styles from './styles.module.scss'
import * as FooterStyles from './Footer.module.scss'

const MainSection = ({ fullpageApi }) => ( 
    <section >
      <div className={ styles.NotFoundContainer }>
        <p className={ styles.NotFoundText }>404 Not Found</p>
        <p>ページが見つかりません</p>
      </div>
    </section>
)

const FooterSection = () => ( 
  <section >
    <Footer.Container styles={ FooterStyles }>
      <Footer.PageLinks/>
      <Footer.Info>
        <Footer.Title/>
        <Footer.FooterTel/>
        {/*<Footer.TimeTable/>*/}
        <Footer.Address/>
        <Footer.FacebookLink/>
      </Footer.Info>
    </Footer.Container>
  </section>
)


export default function Home() {
    return ( 
        <>
        <ReactFullpage
        //fullpage options
        licenseKey = { '240F6328-47E64C97-82A0A364-AE0D2783' }
        scrollingSpeed = { 700 } /* Options here */
        sectionSelector = "section"
        scrollOverflow = "true"
        paddingTop = "40px"
        sectionsColor = {['','#fff','#f5f5f5','#6e6e6c']}
        render={
            ({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper >
                  <MainSection fullpageApi={fullpageApi} />
                  <FooterSection/>
                </ReactFullpage.Wrapper>
              );
            }
          }
          />
          
        </>
      )
}